<template>
    <div class="card box widget-social pt-2 pb-2" :class="[alarmStatus,componentStyle]">
        <div v-if="isLoading">
            <loader-skeleton></loader-skeleton>
        </div>
        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>        
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <div v-show="true" class="hidden md:inline-flex social-icon">
                        <i :class="moduleData.metadata.icon" class="fs-xxlarge icon-md"></i>
                    </div>
                    <div class="flex justify-content-between align-items-center ml-2">
                        <!-- <i :class="iconCold + 'fi fs-xlarge mr-2'" v-tooltip="isCold.label"></i> -->
                        <!-- <i :class="iconRain + 'fi fs-xlarge mr-2'" v-tooltip="isRain.label"></i> -->
                        <!-- <i :class="iconStorm + 'fi fs-xlarge mr-2'" v-tooltip="isStorm.label"></i> -->
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('w_max')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="getModuleValue('volt_230_avg')" :value2="getModuleValue('volt_400_avg')" title="Spannung 230V / 400V"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('va_max')" :value2="getModuleValue('var_max')" title="Schein/Blindleistung"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('frequency')" :value2="getModuleValue('cosphi_max')" title="Frequenz / Leistungsfaktor"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('amp_avg')" :value2="getModuleValue('w_max')" title="Strom / Wirkleistung"></Widget_rowValues2>
                    </ul>
                </div>

                <div class="right flex flex-column">
                    <ul class="widget-list">

                    </ul>
                </div>
            </div>
            <textbox-enat title="Status" value="" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('w_max').icon" :node="getModuleValue('w_max')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import helpers from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
    name: 'widgetEnergyManager',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
    },  
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else {
                return '???';
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                return 'box ptrn-' +  this.content.style.pattern + ' bg-' + this.content.style.background + ' fg-' + this.content.style.foreground;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmStatus: function () {
            // if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
            //     let iCol;
            //     const index = findIndexByDisplayName(this.nodeEntry.children, 'AlarmStatus');
            //     if (this.nodeEntry.children[index].value.length > 0) {
            //         iCol = ' alarm';
            //     } else {
            //         iCol = '';
            //     }
            //     return iCol;
            // } else {
            //     return 'mif-blocked';
            // }
            return '';
        },
        valueListClass: function () {
            return "flex justify-content-center person-tag fg-lightGray bg-olive p-1 text-sm";
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            showDiagMinMax: false,
            showDiagController: false,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Min/Max Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'minmax');
                    },
                },
                {
                    label: 'Regler Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'controller');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        resetCurrentGrp() {
            this.currentGrp = {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            }
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        returnChild(childStr) {
            return helpers.returnChild(this.nodeEntry, childStr);
        },
        returnParameter(node, key, parameter) {
            const path = helpers.findPath(node, key, parameter);
            let obj = helpers.getObjectFromPath(node, path);
            return obj;
        },
        timeDialog(time) {
            console.log(time);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            // this.resetCurrentGrp();
            if (grp === 'minmax') {
                this.currentGrp.enable = this.enable_grp1;
                this.currentGrp.timeSet = this.time_grp1StrBox;
                this.currentGrp.label = 'Begrenzungen';
                this.currentGrp.name = 'Group01';
                this.currentGrp.limits.minValueLuv = this.minValueLuv;
                this.currentGrp.limits.maxValueLuv = this.maxValueLuv;
                this.currentGrp.limits.minValueLee = this.minValueLee;
                this.currentGrp.limits.maxValueLee = this.maxValueLee;
                this.currentGrp.limits.minValueTime = this.minValueTime;
                this.currentGrp.limits.minValueTimeStart = this.minValueTimeStart;
                this.currentGrp.limits.minValueTimeEnd = this.minValueTimeEnd;
                this.currentGrp.limits.minValueTimeLuv = this.minValueTimeLuv;
                this.currentGrp.limits.minValueTimeLee = this.minValueTimeLee;
                this.currentGrp.limits.maxValueTime = this.maxValueTime;
                this.currentGrp.limits.maxValueTimeStart = this.maxValueTimeStart;
                this.currentGrp.limits.maxValueTimeEnd = this.maxValueTimeEnd;
                this.currentGrp.limits.maxValueTimeLuv = this.maxValueTimeLuv;
                this.currentGrp.limits.maxValueTimeLee = this.maxValueTimeLee;
                this.currentGrp.limits.windLimitLuv = this.windLimitLuv;
                this.currentGrp.limits.windLimitLee = this.windLimitLee;
                this.currentGrp.limits.windLimitValueLuv = this.windLimitValueLuv;
                this.currentGrp.limits.windLimitValueLee = this.windLimitValueLee;
                this.currentGrp.limits.rainValueLuv = this.rainValueLuv;
                this.currentGrp.limits.rainValueLee = this.rainValueLee;
                this.currentGrp.limits.stormValueLuv = this.stormValueLuv;
                this.currentGrp.limits.stormValueLee = this.stormValueLee;
                this.currentGrp.limits.frostValueDay = this.frostValueDay;
                this.currentGrp.limits.frostValueNight = this.frostValueNight;
                // this.$refs.minmax.toggle(event);
                this.showDiagMinMax = true;
            }
            else if (grp === 'controller') {
                this.currentGrp.enable = this.enable_grp1;
                this.currentGrp.timeSet = this.time_grp1StrBox;
                this.currentGrp.label = 'Reglerparameter';
                this.currentGrp.name = 'Group01';
                this.currentGrp.controller.abstandValue = this.abstandValue;
                this.currentGrp.controller.abstandValueLuv = this.abstandValueLuv;
                this.currentGrp.controller.controllerPLee = this.controllerPLee;
                this.currentGrp.controller.controllerPLuv = this.controllerPLuv;
                this.currentGrp.controller.controllerATEinfluss = this.controllerATEinfluss;
                this.currentGrp.controller.controllerI = this.controllerI;
                this.currentGrp.controller.switchMin = this.switchMin;
                this.currentGrp.controller.switchMax = this.switchMax;
                this.currentGrp.controller.runtime1 = this.runtime1;
                this.currentGrp.controller.runtime2 = this.runtime2;
                this.currentGrp.controller.orientation = this.orientation;
                this.currentGrp.controller.type = this.type;
                // this.$refs.controller.toggle(event);
                this.showDiagController = true;
            }
        },
        diagToggleMinMax() {
            if (this.showDiagMinMax) {
                this.showDiagMinMax = false;
            } else {
                this.showDiagMinMax = true;
            }
            this.hideOP();
        },
        diagToggleController() {
            if (this.showDiagController) {
                this.showDiagController = false;
            } else {
                this.showDiagController = true;
            }
            this.hideOP();
        },
        hideOP() {
            if (!this.showDiagMinMax && !this.showDiagController) {
                this.resetCurrentGrp();
            }
            // this.currentGrp.timeSet = null;
            // this.currentGrp = { name: null, label: null, enable: null, timeSet: null };
            // this.$refs.minmax.hide();
            // this.$refs.controller.hide();
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            // console.log(ioData);
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}
.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}
.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
    z-index: 999;
}
.card:hover .menu-overlay {
    opacity: 1;
}
.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
</style>